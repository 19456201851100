@font-face {
  font-family: 'Axis';
  font-style: normal;
  font-weight: normal;
  src: local('Axis'), url('/fonts/axis.woff') format('woff');
}

@font-face {
  font-family: "Microsoft PhagsPa";
  src: url("/front/public/fonts/MicrosoftPhagsPa.woff2") format("woff2"),
  url("/front/public/fonts/MicrosoftPhagsPa.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body,
p {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  background: linear-gradient(#5f5a8b, #492831);
  font-family: 'Axis', sans-serif;
}

html, body, #root, .App, .wrapper-container, .items-container, .comparable-item {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
}

.App {
  width: 100%;
  height: 100vh;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wrapper-container {
  height: 100%;
}

.scan-message-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
}

.scan-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.scan-message img {
  display: inline-block;
  margin-bottom: 20px;
}

.scan-message p {
  font-size: 20px;
}

.color-btn {
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  border: 1px solid white;
}

.color-btn.current {
  border: 4px solid white;
}

.configurator-item .images-container {
  position: relative;
  height: 288px;
  margin-top: 150px;
}

.configurator-item .images-container img {
  opacity: 0;
  position: absolute;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.configurator-item .images-container img.current {
  opacity: 1;
}

#main-header {
  background-color: white;
}

#main-header img {
  max-height: 70px;
  padding: 10px 0 0 70px;
}

#main-header li button {
  font-size: 20px;
  color: rgba(0, 0, 0, .5);
  text-transform: uppercase;
  border: none;
  background-color: transparent;
}

#main-header li button:hover, #main-header li button:focus {
  text-decoration: none;
}

#main-header li.current button {
  color: rgb(187, 36, 36);
}

#main-header li {
  border: none;
  border-right: 1px solid rgba(0, 0, 0, .5);
}

#main-header li:last-of-type {
  border-right: none;
}

.configurator-item .header .title {
  font-size: 34px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
}

.configurator-item .header .subtitle {
  text-transform: uppercase;
  font-size: 65px;
  line-height: 44px;
  font-weight: bold;
  color: #ffffff;
}

.equipements-title {
  font-size: 30px;
}

.configurator-item .equipements-content {
  height: 416px;
  max-height: 416px;
  overflow: auto;
  font-size: 16px;
}

.configurator-item .equipements-content::-webkit-scrollbar {
  background-color: grey;
  width: 10px;
}

.configurator-item .equipements-content::-webkit-scrollbar-thumb {
  background-color: red;
}

.send-via-email-btn[disabled] {
  opacity: 0.7;
}
.send-via-email-btn {
  padding: 5px 10px;
  background: #c10001;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  width: auto;
  text-align: left;
  line-height: 18px;
}

.test-drive-btn {
  padding: 11px 50px 11px 10px !important;
  background: url('/images/test-drive-btn.png') no-repeat;
  color: white !important;
  font-weight: bold !important;
  border: none !important;
  cursor: pointer;
  outline: none !important;
  background-size: cover;
  background-position-y: 42%;
  width: 215px;
  text-align: left;
}

.test-drive-btn[disabled] {
  opacity: 0.7;
}
.test-drive-btn:hover, .test-drive-btn:focus {
  background: url('/images/test-drive-btn-hover.png') no-repeat;
  background-size: cover !important;
  background-position-y: 42%;
}

.price-container {
  text-align: center;
}

.price-container .start-from {
  font-size: 22px;
}

.price-container .price {
  font-size: 50px;
  line-height: 58px;
}

button, button:focus {
  outline: none !important;
}

.notification-container {
  position: absolute;
  left: 30px;
  bottom: 30px;
  background-color: black;
  border-radius: 14px;
  padding: 10px 16px;
}

.notification-container p {
  color: white;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
